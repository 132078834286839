// export const PublicKeYOmise = "pkey_5w25u8jq792a6qhysh5";
export const PublicKeYOmise = "pkey_test_5sqyh88gojoro8lm6vi";

export const GOOGLE_MAPS_APIKEY = "AIzaSyBYcbzmF26_rfdB2qPF9NKz5JHYTfk8m7g";
// export const GOOGLE_MAPS_APIKEY = "AIzaSyCIdnH9jJ_mfZXIRDJpTpt_yaCyvhB4aU0";
export const ExchangeApi =
  "https://v6.exchangerate-api.com/v6/544c8c5cc34451bd08bd8d9d/latest/THB";
export const CountryCheckApi = "https://ipapi.co/json/";
export const isLive = true;

export const BASE_URL_LOCAL = "http://127.0.0.1:8000";
export const BASE_URL_LIVE = "https://dogitserver.quloe.info";
export const BASE_URL = isLive ? BASE_URL_LIVE : BASE_URL_LOCAL;
export const BASE_URL_IMAGE = BASE_URL + "/";

export const END_POINT = {
  PdfFileUpload: BASE_URL + "/api/file-upload",
  VideoUpload: BASE_URL + "/api/video-upload",
  ImageUpload: BASE_URL + "/api/picture-upload",
  MoreImageUpload: BASE_URL + "/api/multiple-picture-upload",


  GetSubscribe: BASE_URL + "/api/get-subscribe",
  GetContact: BASE_URL + "/api/get-contact/",
  PostSubscribe: BASE_URL + "/api/post-subscribe",
  PostContact: BASE_URL + "/api/post-contact/",
  UserLogin: BASE_URL + "/api/user-login/",


  //Video
  GetVideo: BASE_URL + "/api/get-video/",
  UploadVideo: BASE_URL + "/api/add-video",
  UpdateVideo: BASE_URL + "/api/update-video/",
  DeleteVideo: BASE_URL + "/api/delete-video/",
  GetVideoById: BASE_URL + "/api/get-video-byid/",


  //Blog
  GetBlog: BASE_URL + "/api/get-blogs/",
  PostBlogs: BASE_URL + "/api/add-blogs",
  UpdateBlogs: BASE_URL + "/api/update-blogs/",
  DeleteBlog: BASE_URL + "/api/delete-blog/",
  GetBlogById: BASE_URL + "/api/get-blog-byid/",


  //HomeTag
  GetHomeTag: BASE_URL + "/api/get-HomeTag/",
  PostHomeTag: BASE_URL + "/api/add-HomeTag",
  UpdateHomeTag: BASE_URL + "/api/update-HomeTag/",
  DeleteHomeTag: BASE_URL + "/api/delete-HomeTag/",
  GetHomeTagById: BASE_URL + "/api/get-HomeTag-byid/",

};

export const METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const HEADERS = {
  Accept: "applicaiton/json",
  // 'Content-Type': 'multipart/form-data',
  "Content-Type": "application/json",
};


