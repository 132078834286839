import BlogPost from './BlogPost';
import React, { useRef, useEffect, useState } from 'react';
import './Landing.css';
import './BlogPage.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import banner from "../../../Images/banner22.jpeg";
import logo from "../../../Images/logo.svg";
import twofon from "../../../Images/twofon.webp";
import dogvideo from "../../../Images/DogitComp2.mp4";
import fotter from "../../../Images/footer_logo2.png"
import playstore from "../../../Images/playstore.svg"
import appstore from "../../../Images/appstore.svg"
import d_one from "../../../Images/appscreen5.png";
import d_two from "../../../Images/Shop.png";
import d_three from "../../../Images/appscreen4.png";
import d_four from "../../../Images/Schedule.png";
import thumb4 from "../../../Images/thumbbig.jpg"
// import d_four from "../../../Images/Schedule.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from "@uidotdev/usehooks";
import 'swiper/css';
import FourFon from './FourFon';
import VideosSwipe from "./VideosSwipe.js";
import OurStory from "./OurStory"
import ForVideos from "./ForVideos"
import prev from "../../../Images/ArrowCircleLeft.svg";
import next from "../../../Images/ArrowCircleRightWhite.svg";
import { Navigation } from 'swiper/modules';
// import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Header from "../../../components/header/Header";
import { Modal } from "react-responsive-modal";
import Swal from 'sweetalert2';
import { BASE_URL, BASE_URL_IMAGE, END_POINT, METHODS } from '../../../helper/helper.js';
import { fetchData } from '../../../helper/ApiCall.js';
import dog1 from "../../../Images/dog.jpg";
import dog2 from "../../../Images/dog2.jpg";
import dog3 from "../../../Images/dog3.jpg";
import { original } from '@reduxjs/toolkit';
import ContactPage from './ContactPage.js';



const Reviews = () => {


    const [blogPosts, setData] = useState([]);

    useEffect(() => {
        ContactHandler();
    }, []);

    const ContactHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetBlog);
            if (result.success == true) {
                setData(result.data);
                console.log(result);

            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <>

            <div className="top-banner"></div>
            <Header />

            <div className='main-container'>

                <div className='main-container'>

                    <div className="term-container">
                        <h1>Reviews </h1>
                        <div className="row" style={{ justifyContent: 'center' }}>

                            <div className="reviews" style={{ border: '3px solid #897a7b', borderRadius: '10px', padding: '20px', marginBottom: '30px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                <h3><b>AlexisLeona</b></h3>
                                <p>I have been a massive fan of Protection Dogs Worldwide for a long time, so I knew I would get the app as soon as it was available. Apart from knowing they are superior dog trainers that raise wonderful well-rounded dogs, the app is also amazing. Very clear lessons that can easily be followed. Well done Leedor, Kayla, and everybody else that had a hand in making it happen.</p>
                            </div>
                            <div className="reviews" style={{ border: '3px solid #897a7b', borderRadius: '10px', padding: '20px', marginBottom: '30px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                <h3><b>Trina Wake</b></h3>
                                <p>Simple and effective lessons easy to fit into my day and create a routine. The instructor is easy to follow and my dog loved the 'games' we play together.</p>
                            </div>
                            <div className="reviews" style={{ border: '3px solid #897a7b', borderRadius: '10px', padding: '20px', marginBottom: '30px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                <h3><b>Rebecca Forster</b></h3>
                                <p>Fantastic app, easy to use, affordable, and a massive help training our 6-month-old dog. Highly recommend.</p>
                            </div>
                            <div className="reviews" style={{ border: '3px solid #897a7b', borderRadius: '10px', padding: '20px', marginBottom: '30px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                <h3><b>Sam K</b></h3>
                                <p>Only just downloaded this but it's super useful. Good tips, easy to use and affordable.</p>
                            </div>
                        </div>

                    </div >

                </div>

            </div>

            <ContactPage />

            <div className='fotterDiv' >
                <div className='container ' >
                    <div className='row '>
                        <div className='col-lg-4'  >
                            <img src={playstore} className='playSImg' alt="footer" />
                            <img src={appstore} className='playSImg' alt="footer" />
                            <div className='footertitle' >USEFUL LINKS</div>
                            <ul className='list-unstyled' >
                                <li><a href='#' >Support</a></li>
                                <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                <li><a href='terms-conditions'>Terms & Conditions</a></li>
                                <li><a href='faq'>FAQ</a></li>
                                <li><a href='#features'>Features</a></li>
                                <li><a href='reviews'>Reviews</a></li>
                                <li><a href='feedback'>Feedback</a></li>
                                <li><a href='/blogs'>Blogs</a></li>

                            </ul>
                        </div>
                        <div className='col-lg-8' >

                        </div>
                        <img src={fotter} alt="footer" />
                    </div>

                </div>
            </div>

        </>

    );
};

export default Reviews;
