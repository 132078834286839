
import LandingPage from "./LandingPage/LandingPage";


const Home = () => {
    return (
        <>
        
        <LandingPage />
        </>
    )
}

export default Home