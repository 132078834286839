import { END_POINT, HEADERS, METHODS } from "./helper";

export const fetchData = (URL, method, body) => {
  console.log(URL);
  return fetch(URL, {
    method: method,
    headers: HEADERS,
    timeout: 10000 /* 10 seconds */,
    body: method === METHODS.GET ? null : body,
    // signal
  })
    .then((response) => {
      return response.json();
    })
    .then(async (result) => {
      // console.log('Result: ' + JSON.stringify(result));

      return result;
    })
    .catch(function (error) {
      console.log("errror=======" + error);
      throw error;
    });
};

export const ImageUpload = async (image) => {
  try {
    var formdata = new FormData();
    formdata.append("image", image);
    console.log(formdata, "formdata");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const response = await fetch(END_POINT.ImageUpload, requestOptions);
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const MoreImageUpload = async (image) => {
  try {
    var formdata = new FormData();

    image.forEach((element, index) => {
      formdata.append("image", element);
    });
    console.log(formdata);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(END_POINT.MoreImageUpload, requestOptions);
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const VideoUpload = async (video) => {
  try {
    var formdata = new FormData();
    formdata.append("video", video);
    console.log(formdata, "formdata");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const response = await fetch(END_POINT.VideoUpload, requestOptions);
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const FileUpload = async (file) => {
  try {
    var formdata = new FormData();
    formdata.append("file", file);
    console.log(formdata, "formdata");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const response = await fetch(END_POINT.PdfFileUpload, requestOptions);
    const json = await response.json();
    return json;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
