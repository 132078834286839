import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../../dashscreen/DashCss.css";

import { useSelector, useDispatch } from "react-redux";

import Swal from "sweetalert2";
import { fetchData } from "../../helper/ApiCall";
import { END_POINT } from "../../helper/helper";

const ContactScreen = () => {
    const [contact, setData] = useState([]);

    useEffect(() => {
        ContactHandler();
    }, []);

    const ContactHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetContact);
            if (result.success == true) {
                setData(result.data);
                console.log(result);

            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>

            <h3> Contacts </h3>

            <div className="mt-4">
                <div id="example_wrapper" class="dataTables_wrapper no-footer">
                    <div
                        class="dataTables_length"
                        id="example_length"
                        style={{ padding: 5 }}
                    >
                        <label>
                            Show
                            <select
                                name="example_length"
                                aria-controls="example"
                                class="mb-4"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            entries
                        </label>
                    </div>
                    <div id="example_filter" class="dataTables_filter mb-4">
                        <label>
                            Search:
                            <input
                                type="search"
                                class=""
                                placeholder=""
                                aria-controls="example"
                            />
                        </label>
                    </div>
                    <table
                        id="example"
                        class="table table-striped table-bordered dataTable no-footer"
                        style={{ width: "100%", whiteSpace: "nowrap" }}
                        role="grid"
                        aria-describedby="example_info"
                    >
                        <thead>
                            <tr role="row">
                                <th
                                    class="sorting_asc"
                                    tabindex="0"
                                    aria-controls="example"
                                    rowspan="1"
                                    colspan="1"
                                    aria-sort="ascending"
                                    aria-label="S.N: activate to sort column descending"
                                    style={{ width: "20px" }}
                                >
                                    S.N
                                </th>
                                <th
                                    class="sorting"
                                    tabindex="0"
                                    aria-controls="example"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label=" Name: activate to sort column ascending"
                                    style={{ width: "146px" }}
                                >
                                    Name
                                </th>
                                <th
                                    class="sorting"
                                    tabindex="0"
                                    aria-controls="example"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label=" Name: activate to sort column ascending"
                                    style={{ width: "146px" }}
                                >
                                    Email
                                </th>

                                <th
                                    class="sorting"
                                    tabindex="0"
                                    aria-controls="example"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label=" Name: activate to sort column ascending"
                                    style={{ width: "146px" }}
                                >
                                    Subject
                                </th>

                                <th
                                    class="sorting"
                                    tabindex="0"
                                    aria-controls="example"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label=" Name: activate to sort column ascending"
                                    style={{ width: "146px" }}
                                >
                                    Message
                                </th>


                                <th
                                    class="sorting"
                                    tabindex="0"
                                    aria-controls="example"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Edit Date: activate to sort column ascending"
                                    style={{ width: "118px" }}
                                >
                                    Date
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {contact.length > 0 ? (
                                contact.map((item, index) => (
                                    <tr role="row" class="odd" key={index}>
                                        <td class="sorting_1"> {index + 1} </td>
                                        <td> {item.name} </td>

                                        <td> {item.email} </td>

                                        <td> {item.subject} </td>

                                        <td> {item.message} </td>

                                        <td> {item.updated_at.split("T")[0]} </td>
                                    </tr>
                                ))
                            ) : (
                                <p>No Contacts Available</p>
                            )}
                        </tbody>
                    </table>
                    <div
                        class="dataTables_info"
                        id="example_info"
                        role="status"
                        aria-live="polite"
                    >
                        Showing 1 to 6 of 6 entries
                    </div>
                    <div
                        class="dataTables_paginate paging_simple_numbers"
                        id="example_paginate"
                    >
                        <a
                            class="paginate_button previous disabled"
                            aria-controls="example"
                            data-dt-idx="0"
                            tabindex="0"
                            id="example_previous"
                        >
                            Previous
                        </a>
                        <span>
                            <a
                                class="paginate_button current"
                                aria-controls="example"
                                data-dt-idx="1"
                                tabindex="0"
                            >
                                1
                            </a>
                        </span>
                        <a
                            class="paginate_button next disabled"
                            aria-controls="example"
                            data-dt-idx="2"
                            tabindex="0"
                            id="example_next"
                        >
                            Next
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactScreen;
