import React, { useState } from 'react';
import './Landing.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import prev from "../../../Images/ArrowCircleLeft.svg";
import next from "../../../Images/ArrowCircleRight.svg";
import thumb1 from "../../../Images/img1.png"
import thumb2 from "../../../Images/img2.png"
import thumb3 from "../../../Images/img3.png"
import thumb4 from "../../../Images/img2.png"



// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';



const ForVideos = () => {
    const [videoUrl, setVideoUrl] = useState(null)
    const [videoNum, setVideoNum] = useState(0)

    const videoHandler = (url) => {
        console.log("url21", url)
        setVideoUrl(url)

    }


    return (
        <>
            <Swiper
                spaceBetween={30}
                loop={true}
                effect={'fade'}
                navigation={{
                    nextEl: '.netVideobtn',
                    prevEl: '.prevVideobtn',
                }}

                modules={[EffectFade, Navigation, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div class="videoImgBox last_video">

                        {videoUrl && videoNum === 1 ?
                            <iframe src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            :
                            <div>
                                <img src={thumb1} alt="TDogit_thumb" />
                                <div class="play-icon cursor-pointer " onClick={() => { videoHandler('https://www.youtube.com/embed/iskU-9v4ew4?si=Z230Q-ISzh-rzYJU'); setVideoNum(1) }}  ><i class="bi bi-play-circle" ></i></div>

                            </div>
                        }

                        <div class="VideoTitle">Dog training - Different Ways To Teach The Close</div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="videoImgBox last_video">
                        {videoUrl && videoNum === 2 ?

                            <iframe src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            :
                            <div>
                                <img src={thumb2} alt="TDogit_thumb" />
                                <div class="play-icon cursor-pointer " onClick={() => { videoHandler("https://www.youtube.com/embed/dLftYM_Wszg?si=89Nk6GwPEgR31OtB"); setVideoNum(2) }} ><i class="bi bi-play-circle" ></i></div>

                            </div>
                        }

                        <div class="VideoTitle">Dog training - Luring a puppy.</div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="videoImgBox last_video">
                        {videoUrl && videoNum === 3 ?

                            <iframe src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            :
                            <div>
                                <img src={thumb3} alt="TDogit_thumb" />
                                <div class="play-icon cursor-pointer " onClick={() => { videoHandler("https://www.youtube.com/embed/7RDlx7wjqsk?si=I-Kbx5fP3-oBiGwH"); setVideoNum(3) }}  ><i class="bi bi-play-circle" ></i></div>

                            </div>
                        }

                        <div class="VideoTitle">Dog training - training in a positive mind frame</div>

                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div class="videoImgBox last_video">
                        {videoUrl && videoNum === 4 ?

                            <iframe src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            :
                            <div>
                                <img src={thumb4} alt="TDogit_thumb" />
                                <div class="play-icon cursor-pointer " onClick={() => { videoHandler("https://www.youtube.com/embed/3cgR2ceCYw0?si=PoeyG6YcfFQucZCD"); setVideoNum(4) }}  ><i class="bi bi-play-circle" ></i></div>

                            </div>
                        }

                        <div class="VideoTitle">Dog Training - Engaging with your puppy</div>

                    </div>
                </SwiperSlide>
            </Swiper>
            <div className='moveVideobtn '>
                <div className='prevVideobtn' >
                    <img src={prev} alt="prev" />
                </div>
                <div className='netVideobtn'>
                    <img src={next} alt="next" />
                </div>
            </div>
        </>
    );
}

export default ForVideos

