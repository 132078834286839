import BlogPost from './BlogPost';
import React, { useRef, useEffect, useState } from 'react';
import './Landing.css';
import './BlogPage.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import banner from "../../../Images/banner22.jpeg";
import logo from "../../../Images/logo.svg";
import twofon from "../../../Images/twofon.webp";
import dogvideo from "../../../Images/DogitComp2.mp4";
import fotter from "../../../Images/footer_logo2.png"
import playstore from "../../../Images/playstore.svg"
import appstore from "../../../Images/appstore.svg"
import d_one from "../../../Images/appscreen5.png";
import d_two from "../../../Images/Shop.png";
import d_three from "../../../Images/appscreen4.png";
import d_four from "../../../Images/Schedule.png";
import thumb4 from "../../../Images/thumbbig.jpg"
// import d_four from "../../../Images/Schedule.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from "@uidotdev/usehooks";
import 'swiper/css';
import FourFon from './FourFon';
import VideosSwipe from "./VideosSwipe.js";
import OurStory from "./OurStory"
import ForVideos from "./ForVideos"
import prev from "../../../Images/ArrowCircleLeft.svg";
import next from "../../../Images/ArrowCircleRightWhite.svg";
import { Navigation } from 'swiper/modules';
// import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Header from "../../../components/header/Header";
import { Modal } from "react-responsive-modal";
import Swal from 'sweetalert2';
import { BASE_URL, BASE_URL_IMAGE, END_POINT, METHODS } from '../../../helper/helper.js';
import { fetchData } from '../../../helper/ApiCall.js';
import dog1 from "../../../Images/dog.jpg";
import dog2 from "../../../Images/dog2.jpg";
import dog3 from "../../../Images/dog3.jpg";
import { original } from '@reduxjs/toolkit';
import ContactPage from './ContactPage.js';


const Feedback = () => {


    const [blogPosts, setData] = useState([]);

    useEffect(() => {
        ContactHandler();
    }, []);

    const ContactHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetBlog);
            if (result.success == true) {
                setData(result.data);
                console.log(result);

            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <>

            <div className="top-banner"></div>
            <Header />

            <div className='main-container'>

                <div className="term-container">
                    <h1> Feedback </h1>
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <ol type='1'>
                            <li>
                                <br></br>
                                <h3><b>1. User-Friendly Feedback Channels:</b></h3>
                                <br></br>
                                <p>We have established user-friendly channels within the app for you to submit your feedback effortlessly. Look for the dedicated feedback section or button, where you can share your thoughts directly with our team.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>2. Rating and Review:</b></h3>
                                <br></br>
                                <p>Consider leaving a rating and review on the app store or platform where you downloaded the App. Your reviews not only help us understand your experience but also assist potential users in making informed decisions.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>3. Feature Requests:</b></h3>
                                <br></br>
                                <p>If there are specific features you would like to see in future updates, please let us know. We appreciate innovative ideas that can make the app even more effective and enjoyable for you and your furry friends.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>4. Bug Reports:</b></h3>
                                <br></br>
                                <p>If you encounter any technical issues, glitches, or bugs while using the app, please report them. Be specific about the problem, including when it occurred, what you were doing at the time, and any error messages you received. This information is invaluable for our development team in resolving issues promptly.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>5. Positive Stories:</b></h3>
                                <br></br>
                                <p>Share your success stories! If the App has helped you and your dog achieve training milestones, we'd love to hear about it. Your positive experiences inspire us and motivate our team to continue providing valuable services.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>6. Social Media Engagement:</b></h3>
                                <br></br>
                                <p>Connect with us on social media platforms to stay updated on app developments and share your feedback publicly. We actively monitor social media channels for user input and engage in conversations to address questions and concerns.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>7. Customer Support:</b></h3>
                                <br></br>
                                <p>For personalized assistance, reach out to our customer support team. They are available to address any issues you may encounter and can guide you through using the app effectively.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>8. Participate in Surveys:</b></h3>
                                <br></br>
                                <p>Periodically, we may send out surveys to gather specific feedback on new features, updates, or user preferences. Your participation in these surveys is greatly appreciated and contributes to the ongoing improvement of the App.</p>
                            </li>
                            <br></br>

                        </ol>
                        <br></br>

                        <p>Your feedback is essential in shaping the future of the Dogit App, and we thank you for taking the time to share your thoughts. We are committed to providing a top-notch experience for you and your canine companion, and your input is instrumental in achieving that goal.</p>
                    </div>

                </div >
            </div>

            <ContactPage />

            <div className='fotterDiv' >
                <div className='container ' >
                    <div className='row '>
                        <div className='col-lg-4'  >
                            <img src={playstore} className='playSImg' alt="footer" />
                            <img src={appstore} className='playSImg' alt="footer" />
                            <div className='footertitle' >USEFUL LINKS</div>
                            <ul className='list-unstyled' >
                                <li><a href='#' >Support</a></li>
                                <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                <li><a href='terms-conditions'>Terms & Conditions</a></li>
                                <li><a href='faq'>FAQ</a></li>
                                <li><a href='#features'>Features</a></li>
                                <li><a href='reviews'>Reviews</a></li>
                                <li><a href='feedback'>Feedback</a></li>
                                <li><a href='/blogs'>Blogs</a></li>

                            </ul>
                        </div>
                        <div className='col-lg-8' >

                        </div>
                        <img src={fotter} alt="footer" />
                    </div>

                </div>
            </div>

        </>

    );
};

export default Feedback;
