import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { END_POINT, METHODS } from "../../helper/helper";
import { fetchData } from "../../helper/ApiCall";

const AddVideoScreen = () => {
    const navigate = useNavigate();
    let location = useLocation();
    const id = location?.state?.id;

    const [image, setimage] = useState("");


    useEffect(() => {

        if (id != undefined) {
            console.log(id);
            bannerHandler();
        }
    }, []);


    const bannerHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetVideoById + id);
            console.log("bkbkk", result);
            if (result.success == true) {
                setformData({

                    title: result.data.title,
                    video: result.data.video,
                    image: result.data.image,

                });


            }
        } catch (err) {
            console.log(err);
        }
    };


    const [formData, setformData] = useState({
        title: "",
        video: "",
        image: "",
    });

    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async () => {
        try {
            const finalEndpoint = id !== undefined ? `${END_POINT.UpdateVideo}${id}` : END_POINT.UploadVideo;

            const formdata = new FormData();
            formdata.append("title", formData.title);
            formdata.append("video", formData.video);
            formdata.append("image", image);

            const requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            const response = await fetch(finalEndpoint, requestOptions);
            const result = await response.text();
            console.log(result);

            if (response.ok) {
                Swal.fire({
                    title: "Confirmed",
                    text: "Successfully posted",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("../video");
                    }
                });
            } else {
                // Handle error here
            }
        } catch (error) {
            console.log('error', error);
            // Handle error here
        }
    };

    return (
        <div>
            <div>
                <h3 className="mb-4" style={{ color: "black" }}>
                    <b>Add Video </b>
                </h3>
            </div>

            <form encType="multipart/form-data">
                <div className="row mb-4">

                    <div className="col-lg-6 md-6 sm-12">
                        <div>
                            <span className="label-style" style={{ color: "black" }}>Title</span> <br />
                            <input
                                type="text"
                                id="title"
                                name="title"
                                className="input-fields-style"
                                value={formData.title}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 md-6 sm-12">
                        <div>
                            <span className="label-style" style={{ color: "black" }}>Video url</span> <br />
                            <input
                                type="text"
                                id="video"
                                name="video"
                                className="input-fields-style"
                                value={formData.video}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>



                    <div className="col-lg-6 md-6 sm-12">
                        <div>
                            <span className="label-style" style={{ color: "black" }}>Image</span> <br />
                            <input
                                type="file"
                                id="image"
                                name="image"
                                className="input-fields-style"
                                value={formData.image}
                                accept="image/*"
                                onChange={(e) => {
                                    console.log(e.target.files[0]);
                                    setimage(e.target.files[0]);
                                }}
                            />
                        </div>
                    </div>




                </div>

                <div className="d-flex justify-content-start">
                    <button
                        type="button"
                        className="btn btn-primary m-2"
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </button>

                    <button
                        className="btn btn-danger m-2"
                        onClick={() => navigate("../video")}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddVideoScreen;
