import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { END_POINT, METHODS } from "../../helper/helper";
import { fetchData } from "../../helper/ApiCall";

const AddHomeTags = () => {
    const navigate = useNavigate();
    let location = useLocation();
    const id = location?.state?.id;

    const [image, setimage] = useState("");

    useEffect(() => {

        if (id != undefined) {
            console.log(id);
            bannerHandler();
        }
    }, []);


    const bannerHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetHomeTagById + id);
            console.log("bkbkk", result);
            if (result.success == true) {
                setformData({


                    meta_title: result.data[0].meta_title,
                    meta_desc: result.data[0].meta_desc,
                    meta_keyword: result.data[0].meta_keyword,
                    og_tag: result.data[0].og_tag,


                });

            }
        } catch (err) {
            console.log(err);
        }
    };


    const [formData, setformData] = useState({

        meta_title: "",
        meta_desc: "",
        meta_keyword: "",
        og_tag: "",
    });

    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };





    const handleSubmit = async () => {
        try {
            const finalEndpoint = id !== undefined ? `${END_POINT.UpdateHomeTag}${id}` : END_POINT.PostHomeTag;

            const formdata = new FormData();
            formdata.append("meta_title", formData.meta_title);
            formdata.append("meta_desc", formData.meta_desc);
            formdata.append("meta_keyword", formData.meta_keyword);
            formdata.append("og_tag", formData.og_tag);

            const requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            const response = await fetch(finalEndpoint, requestOptions);
            const result = await response.text();
            console.log(result);

            if (response.ok) {
                Swal.fire({
                    title: "Confirmed",
                    text: "Successfully posted",
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("../home-meta-tags");
                    }
                });
            } else {
                // Handle error here
            }
        } catch (error) {
            console.log('error', error);
            // Handle error here
        }
    };


    return (
        <div>
            <div>
                <h3 className="mb-4" style={{ color: "black" }}>
                    <b>Add Tags  </b>
                </h3>
            </div>

            <form encType="multipart/form-data">
                <div className="row mb-4">



                    <div className="col-lg-6 md-6 sm-12">
                        <div>
                            <span className="label-style" style={{ color: "black" }}>Meta Title</span> <br />
                            <input
                                type="text"
                                id="meta_title"
                                name="meta_title"
                                className="input-fields-style"
                                value={formData.meta_title}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 md-6 sm-12">
                        <div>
                            <span className="label-style" style={{ color: "black" }}>Meta Description</span> <br />
                            <input
                                type="text"
                                id="meta_desc"
                                name="meta_desc"
                                className="input-fields-style"
                                value={formData.meta_desc}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 md-6 sm-12">
                        <div>
                            <span className="label-style" style={{ color: "black" }}>Meta Keyword</span> <br />
                            <input
                                type="text"
                                id="meta_keyword"
                                name="meta_keyword"
                                className="input-fields-style"
                                value={formData.meta_keyword}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>

                    <div className="col-lg-6 md-6 sm-12">
                        <div>
                            <span className="label-style" style={{ color: "black" }}>Og Tags</span> <br />
                            <input
                                type="text"
                                id="og_tag"
                                name="og_tag"
                                className="input-fields-style"
                                value={formData.og_tag}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>


                </div>

                <div className="d-flex justify-content-start">
                    <button
                        type="button"
                        className="btn btn-primary m-2"
                        onClick={() => handleSubmit()}

                    >
                        Submit
                    </button>

                    <button
                        className="btn btn-danger m-2"
                        onClick={() => navigate("../home-meta-tags")}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddHomeTags;
