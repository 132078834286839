import BlogPost from './BlogPost';
import React, { useRef, useEffect, useState } from 'react';
import './Landing.css';
import './BlogPage.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import banner from "../../../Images/banner22.jpeg";
import logo from "../../../Images/logo.svg";
import twofon from "../../../Images/twofon.webp";
import dogvideo from "../../../Images/DogitComp2.mp4";
import fotter from "../../../Images/footer_logo2.png"
import playstore from "../../../Images/playstore.svg"
import appstore from "../../../Images/appstore.svg"
import d_one from "../../../Images/appscreen5.png";
import d_two from "../../../Images/Shop.png";
import d_three from "../../../Images/appscreen4.png";
import d_four from "../../../Images/Schedule.png";
import thumb4 from "../../../Images/thumbbig.jpg"
// import d_four from "../../../Images/Schedule.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from "@uidotdev/usehooks";
import 'swiper/css';
import FourFon from './FourFon';
import VideosSwipe from "./VideosSwipe.js";
import OurStory from "./OurStory"
import ForVideos from "./ForVideos"
import prev from "../../../Images/ArrowCircleLeft.svg";
import next from "../../../Images/ArrowCircleRightWhite.svg";
import { Navigation } from 'swiper/modules';
// import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Header from "../../../components/header/Header";
import { Modal } from "react-responsive-modal";
import Swal from 'sweetalert2';
import { BASE_URL, BASE_URL_IMAGE, END_POINT, METHODS } from '../../../helper/helper.js';
import { fetchData } from '../../../helper/ApiCall.js';
import dog1 from "../../../Images/dog.jpg";
import dog2 from "../../../Images/dog2.jpg";
import dog3 from "../../../Images/dog3.jpg";
import { original } from '@reduxjs/toolkit';
import ContactPage from './ContactPage.js';


const Faq = () => {


    const [blogPosts, setData] = useState([]);

    useEffect(() => {
        ContactHandler();
    }, []);

    const ContactHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetBlog);
            if (result.success == true) {
                setData(result.data);
                console.log(result);

            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <>

            <div className="top-banner"></div>
            <Header />

            <div className='main-container'>

                <div className="term-container">
                    <h1>FAQ  </h1>
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <ol type='1'>
                            <li>
                                <br></br>
                                <h3><b>Q1: What age is suitable to start training my dog?</b></h3>
                                <br></br>
                                <p>It's beneficial to start basic training as early as possible, ideally when your dog is a puppy. However, dogs of any age can learn new behaviors with patience and consistent training.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q2: How long should training sessions be?</b></h3>
                                <br></br>
                                <p>Keep training sessions short and positive, aiming for about 5-10 minutes for puppies and up to 15 minutes for adult dogs. Multiple short sessions throughout the day are more effective than one long session.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q3: What are the basic commands I should teach my dog?</b></h3>
                                <br></br>
                                <p>Start with essential commands like sit, stay, come, and down. These form the foundation for good behavior and effective communication with your dog.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q4: How can I house train my puppy?</b></h3>
                                <br></br>
                                <p>Establish a consistent schedule for feeding and bathroom breaks. Use positive reinforcement when your puppy eliminates outside and be patient, as accidents may happen during the learning process.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q5: My dog is not responding well to training. What should I do?</b></h3>
                                <br></br>
                                <p>Stay patient and consistent. Assess if the training environment is free from distractions, use high-value treats, and break down commands into smaller steps. If difficulties persist, consider seeking advice from a professional dog trainer.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q6: Is punishment an effective training method?</b></h3>
                                <br></br>
                                <p>Positive reinforcement is generally more effective than punishment. Reward good behavior with treats or praise, and redirect or ignore unwanted behavior. Harsh punishment can lead to fear or aggression.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q7: How do I socialize my dog?</b></h3>
                                <br></br>
                                <p>Gradually expose your dog to various environments, people, and other dogs. Use positive reinforcement to reward calm and friendly behavior. Enroll in puppy socialization classes for structured interactions.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q8: Can older dogs learn new tricks?</b></h3>
                                <br></br>
                                <p>Absolutely! While puppies tend to learn quickly, older dogs can also learn new commands and behaviors with patience and consistent training.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q9: How do I stop excessive barking?</b></h3>
                                <br></br>
                                <p>Identify the cause of the barking and address it. Use commands like "quiet," reward silence, and avoid reinforcing barking by giving attention during such behavior.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q10: Should I hire a professional dog trainer?</b></h3>
                                <br></br>
                                <p>If you encounter challenges or want specialized training, hiring a professional dog trainer can be beneficial. They provide personalized guidance and address specific behavior issues.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>Q11: Can I train my dog without treats?</b></h3>
                                <br></br>
                                <p>While treats are effective for positive reinforcement, you can use praise, toys, or other rewards based on your dog's preferences. The key is to find what motivates your dog.</p>
                            </li>
                            <br></br>

                        </ol>
                        <p>Remember, each dog is unique, and training methods may need to be adjusted based on individual needs. Consistency, positive reinforcement, and patience are key elements in successful dog training. If you have specific concerns, consulting with a professional dog trainer or behaviorist is recommended.</p>
                    </div>

                </div >
            </div>

            <ContactPage />

            <div className='fotterDiv' >
                <div className='container ' >
                    <div className='row '>
                        <div className='col-lg-4'  >
                            <img src={playstore} className='playSImg' alt="footer" />
                            <img src={appstore} className='playSImg' alt="footer" />
                            <div className='footertitle' >USEFUL LINKS</div>
                            <ul className='list-unstyled' >
                                <li><a href='#' >Support</a></li>
                                <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                <li><a href='terms-conditions'>Terms & Conditions</a></li>
                                <li><a href='faq'>FAQ</a></li>
                                <li><a href='#features'>Features</a></li>
                                <li><a href='reviews'>Reviews</a></li>
                                <li><a href='feedback'>Feedback</a></li>
                                <li><a href='/blogs'>Blogs</a></li>

                            </ul>
                        </div>
                        <div className='col-lg-8' >

                        </div>
                        <img src={fotter} alt="footer" />
                    </div>

                </div>
            </div>

        </>

    );
};

export default Faq;
