import BlogPost from './BlogPost';
import React, { useRef, useEffect, useState } from 'react';
import './Landing.css';
import './BlogPage.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import banner from "../../../Images/banner22.jpeg";
import logo from "../../../Images/logo.svg";
import twofon from "../../../Images/twofon.webp";
import dogvideo from "../../../Images/DogitComp2.mp4";
import fotter from "../../../Images/footer_logo2.png"
import playstore from "../../../Images/playstore.svg"
import appstore from "../../../Images/appstore.svg"
import d_one from "../../../Images/appscreen5.png";
import d_two from "../../../Images/Shop.png";
import d_three from "../../../Images/appscreen4.png";
import d_four from "../../../Images/Schedule.png";
import thumb4 from "../../../Images/thumbbig.jpg"
// import d_four from "../../../Images/Schedule.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from "@uidotdev/usehooks";
import 'swiper/css';
import FourFon from './FourFon';
import VideosSwipe from "./VideosSwipe.js";
import OurStory from "./OurStory"
import ForVideos from "./ForVideos"
import prev from "../../../Images/ArrowCircleLeft.svg";
import next from "../../../Images/ArrowCircleRightWhite.svg";
import { Navigation } from 'swiper/modules';
// import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Header from "../../../components/header/Header";
import { Modal } from "react-responsive-modal";
import Swal from 'sweetalert2';
import { BASE_URL, BASE_URL_IMAGE, END_POINT, METHODS } from '../../../helper/helper.js';
import { fetchData } from '../../../helper/ApiCall.js';
import dog1 from "../../../Images/dog.jpg";
import dog2 from "../../../Images/dog2.jpg";
import dog3 from "../../../Images/dog3.jpg";
import { original } from '@reduxjs/toolkit';
import ContactPage from './ContactPage.js';


const TermsCondition = () => {


    const [blogPosts, setData] = useState([]);

    useEffect(() => {
        ContactHandler();
    }, []);

    const ContactHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetBlog);
            if (result.success == true) {
                setData(result.data);
                console.log(result);

            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <>

            <div className="top-banner"></div>
            <Header />

            <div className='main-container'>

                <div className="term-container">
                    <h1>Terms And Conditions </h1>
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <ol type='1'>
                            <li>
                                <br></br>
                                <h3><b>1. Acceptance of Terms</b></h3>
                                <br></br>
                                <p>By using the Dogit (the "App"), you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use the App.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>2. User Eligibility</b></h3>
                                <br></br>
                                <p>The App is intended for users who are at least 18 years old. By using the App, you confirm that you are at least 18 years of age.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>3. Account Registration</b></h3>
                                <br></br>
                                <p>To access certain features of the App, you may need to register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>4. Appropriate Use</b></h3>
                                <br></br>
                                <p>You agree to use the App only for lawful purposes and in a manner consistent with all applicable laws and regulations. You will not engage in any conduct that could harm the App, its users, or third parties.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>5. User Content</b></h3>
                                <br></br>
                                <p>By using the App, you may submit content, such as photos, videos, and text. You retain ownership of any intellectual property rights in the content you submit. By submitting content, you grant the App a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, publish, distribute, and display such content for the purpose of providing and improving the App.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>6. Privacy Policy</b></h3>
                                <br></br>
                                <p>Our Privacy Policy explains how we collect, use, and disclose information about you. By using the App, you agree to the terms of our Privacy Policy.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>7. Payments and Subscriptions</b></h3>
                                <br></br>
                                <p>Some features of the App may require payment or subscription. Payment details and subscription terms will be outlined separately and must be agreed upon before accessing such features.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>8. Termination</b></h3>
                                <br></br>
                                <p>We reserve the right to terminate or suspend your account and access to the App at our sole discretion, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users or us.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>9. Limitation of Liability</b></h3>
                                <br></br>
                                <p>The App is provided "as is" without any warranties, and we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the App.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>10. Changes to Terms</b></h3>
                                <br></br>
                                <p>We reserve the right to modify or update these Terms and Conditions at any time. You are responsible for regularly reviewing these terms, and your continued use of the App constitutes acceptance of any changes.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>11. Governing Law</b></h3>
                                <br></br>
                                <p>These Terms and Conditions are governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.</p>
                            </li>
                        </ol>
                        <p>By using the Dogit App, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please discontinue use of the App.</p>
                    </div>

                </div >
            </div>

            <ContactPage />

            <div className='fotterDiv' >
                <div className='container ' >
                    <div className='row '>
                        <div className='col-lg-4'  >
                            <img src={playstore} className='playSImg' alt="footer" />
                            <img src={appstore} className='playSImg' alt="footer" />
                            <div className='footertitle' >USEFUL LINKS</div>
                            <ul className='list-unstyled' >
                                <li><a href='#' >Support</a></li>
                                <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                <li><a href='terms-conditions'>Terms & Conditions</a></li>
                                <li><a href='faq'>FAQ</a></li>
                                <li><a href='#features'>Features</a></li>
                                <li><a href='reviews'>Reviews</a></li>
                                <li><a href='feedback'>Feedback</a></li>
                                <li><a href='/blogs'>Blogs</a></li>

                            </ul>
                        </div>
                        <div className='col-lg-8' >

                        </div>
                        <img src={fotter} alt="footer" />
                    </div>

                </div>
            </div>

        </>

    );
};

export default TermsCondition;
