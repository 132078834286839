import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BASE_URL_IMAGE, END_POINT } from "../../../helper/helper.js";
import { fetchData } from "../../../helper/ApiCall.js";
import Header from "../../header/Header.js";
import fotter from "../../../Images/footer_logo2.png"
import playstore from "../../../Images/playstore.svg"
import appstore from "../../../Images/appstore.svg"
import './BlogPage.css';


const BlogDetailPage = () => {
    const navigate = useNavigate();
    let location = useLocation();
    const id = location?.state?.id;

    const [blogData, setBlogData] = useState(null);

    useEffect(() => {
        if (id) {
            fetchBlogData(id);
        }
    }, [id]);

    const fetchBlogData = async (id) => {
        try {
            const result = await fetchData(END_POINT.GetBlogById + id);
            if (result.success) {
                setBlogData(result.data[0]);
            }
        } catch (error) {
            console.error("Error fetching blog data:", error);
        }
    };

    return (
        <>
            <Header />
            <div className="container mt-5 blog-detail-container">
                {blogData ? (
                    <div>
                        <h1 className="blog-title">{blogData.title}</h1>
                        <img src={BASE_URL_IMAGE + blogData.image} alt={blogData.title} className="blog-image" />
                        <p className="blog-description">{blogData.desc}</p>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>

            <div className='fotterDiv' >
                <div className='container ' >
                    <div className='row '>
                        <div className='col-lg-4'  >
                            <img src={playstore} className='playSImg' alt="footer" />
                            <img src={appstore} className='playSImg' alt="footer" />
                            <div className='footertitle' >USEFUL LINKS</div>
                            <ul className='list-unstyled' >
                                <li><a href='#' >Support</a></li>
                                <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                <li><a href='terms-conditions'>Terms & Conditions</a></li>
                                <li><a href='faq'>FAQ</a></li>
                                <li><a href='#features'>Features</a></li>
                                <li><a href='reviews'>Reviews</a></li>
                                <li><a href='feedback'>Feedback</a></li>
                                <li><a href='/blogs'>Blogs</a></li>

                            </ul>
                        </div>
                        <div className='col-lg-8' >

                        </div>
                        <img src={fotter} alt="footer" />
                    </div>

                </div>
            </div>
        </>
    );
};

export default BlogDetailPage;
