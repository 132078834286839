import React, { useRef, useEffect, useState } from 'react';
import './Landing.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import banner from "../../../Images/banner22.jpeg";
import logo from "../../../Images/logo2.svg";
// import mobilebanner from "../../../Images/mobilebanner.webp";
import twofon from "../../../Images/twofon.webp";
import dogvideo from "../../../Images/DogitComp2.mp4";
// import dogvideo1 from "../../../Images/dogvideo1.mp4";
// import dogvideo2 from "../../../Images/dogvideo2.mp4";
import fotter from "../../../Images/footer_logo2.png"
import playstore from "../../../Images/playstore.svg"
import appstore from "../../../Images/appstore.svg"
import d_one from "../../../Images/screen1.png";
import d_two from "../../../Images/screen2.png";
import d_three from "../../../Images/screen3.png";
import d_four from "../../../Images/screen4.png";
import thumb4 from "../../../Images/thumbbig.jpg"
// import d_four from "../../../Images/Schedule.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from "@uidotdev/usehooks";
import 'swiper/css';
import FourFon from './FourFon';
import VideosSwipe from "./VideosSwipe.js";
import OurStory from "./OurStory"
import ForVideos from "./ForVideos"
import prev from "../../../Images/ArrowCircleLeft.svg";
import next from "../../../Images/ArrowCircleRightWhite.svg";
import { Navigation } from 'swiper/modules';
// import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Header from "../../../components/header/Header";
import { Modal } from "react-responsive-modal";
import Swal from 'sweetalert2';
import { END_POINT, METHODS } from '../../../helper/helper.js';
import { fetchData } from '../../../helper/ApiCall.js';
import thumb3 from "../../../Images/img3.png"




const ContactPage = () => {
    const isSmallDevice = useMediaQuery("only screen and (max-width : 767px)");
    const [videoUrl, setVideoUrl] = useState(null)
    const videoRef = useRef(null);

    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);

    };

    const onCloseModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            video.oncanplay = () => {
                video.play();
            };
        }
    }, []);

    const videoHandler = (url) => {
        console.log("url21", url)
        setVideoUrl(url)

    }

    const appscreenRef = useRef(null);
    const trainingRef = useRef(null);
    const featuresRef = useRef(null);
    const ourStoryRef = useRef(null);
    const newsletterRef = useRef(null);
    const contactUsRef = useRef(null);

    const scrollToSection = (sectionId) => {
        let targetRef;
        switch (sectionId) {
            case 'appscreen':
                targetRef = appscreenRef;
                break;
            case 'training':
                targetRef = trainingRef;
                break;
            case 'features':
                targetRef = featuresRef;
                break;
            case 'ourStory':
                targetRef = ourStoryRef;
                break;
            case 'newsletter':
                targetRef = newsletterRef;
                break;
            case 'contactus':
                targetRef = contactUsRef;
                break;
            default:
                break;
        }

        if (targetRef) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [formData, setformData] = useState({
        name: "",
        email: "",
        message: "",
        subject: "",
    });

    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const ContactSubmit = async (e) => {
        var raw = JSON.stringify({
            email: formData.email,
            name: formData.name,
            subject: formData.subject,
            message: formData.message,
        });
        console.log(raw);

        try {
            const finalEndpoint = END_POINT.PostContact;
            const result = await fetchData(finalEndpoint, METHODS.POST, raw);
            console.log("API response:", result);
            if (result.success == true) {
                // setFormSubmitted(result.data);
                Swal.fire({
                    title: "Confirmed",
                    text: result.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => { });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const SubscribeSubmit = async (e) => {
        var raw = JSON.stringify({
            email: formData.email,
        });
        console.log(raw);

        try {
            const finalEndpoint = END_POINT.PostSubscribe;
            const result = await fetchData(finalEndpoint, METHODS.POST, raw);
            console.log("API response:", result);
            if (result.success == true) {
                // setFormSubmitted(result.data);
                Swal.fire({
                    title: "Confirmed",
                    text: result.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => { });
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>


            <div className='container-fluid'>
                <div className='row'>
                    <div >
                        <div class="contact-form-wrapper d-flex justify-content-center" ref={newsletterRef} id="newsletter" >
                            <div class="content-wrap">

                                <h3 class="title">Our Newsletter</h3>

                                <p class="description">We’re constantly learning new things, and don’t like to keep them to ourselves! By subscribing to our newsletter you’ll be the first to hear about the exciting stuff that’s come our way. We don’t gatekeep tips and tricks, and our newsletter is the best and quickest way to stay in touch.
                                </p>

                                <div>
                                    <input type="email" name="email" id="email" class="form-control rounded border-white mb-3 form-input" placeholder="Email" required
                                        value={formData.email}
                                        onChange={(e) => handleChange(e)} />
                                </div>



                                <div class="submit-button-wrapper">
                                    <input type="submit" value="Submit" onClick={() => {
                                        SubscribeSubmit();
                                    }} />
                                </div>

                                <p class="description">We don’t share your personal information with anyone or company. Check out our Privacy Policy for more information.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='form-box' ref={contactUsRef} id="contactus" >
                        <div class="contact-form-wrapper d-flex justify-content-center">
                            <div class="contact-form">
                                <h3 className="title" style={{ color: 'black' }}>GET IN TOUCH</h3>


                                <div className='row'>
                                    <div className="boxes col-6">
                                        <input type="text" class="form-control rounded border-white  form-input" id="name"
                                            name="name" placeholder="Name"
                                            value={formData.name}
                                            onChange={(e) => handleChange(e)}
                                            required />
                                    </div>
                                    <div className="boxes col-6" >
                                        <input type="email" name="email" id="email" class="form-control rounded border-white form-input" placeholder="Email" required
                                            value={formData.email}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>

                                </div>
                                <div className="boxes" >
                                    <input type="text" class="form-control rounded border-white form-input" id="subject" placeholder="Subject" name="subject" required
                                        value={formData.subject}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div className="boxes " >
                                    <textarea id="message" name="message" class="form-control rounded border-white form-text-area" rows="5" cols="30" placeholder="Message" required
                                        value={formData.message}
                                        onChange={(e) => handleChange(e)}
                                    ></textarea>
                                </div>
                                <div class="submit-button-wrapper">
                                    <input type="submit" value="Submit" onClick={() => {
                                        ContactSubmit();
                                    }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>

    )
}

export default ContactPage

