import BlogPost from './BlogPost';
import React, { useRef, useEffect, useState } from 'react';
import './Landing.css';
import './BlogPage.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from "react-router-dom";
import 'bootstrap-icons/font/bootstrap-icons.css';
import banner from "../../../Images/banner22.jpeg";
import logo from "../../../Images/logo.svg";
import twofon from "../../../Images/twofon.webp";
import dogvideo from "../../../Images/DogitComp2.mp4";
import fotter from "../../../Images/footer_logo2.png"
import playstore from "../../../Images/playstore.svg"
import appstore from "../../../Images/appstore.svg"
import d_one from "../../../Images/appscreen5.png";
import d_two from "../../../Images/Shop.png";
import d_three from "../../../Images/appscreen4.png";
import d_four from "../../../Images/Schedule.png";
import thumb4 from "../../../Images/thumbbig.jpg"
// import d_four from "../../../Images/Schedule.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from "@uidotdev/usehooks";
import 'swiper/css';
import FourFon from './FourFon';
import VideosSwipe from "./VideosSwipe.js";
import OurStory from "./OurStory"
import ForVideos from "./ForVideos"
import prev from "../../../Images/ArrowCircleLeft.svg";
import next from "../../../Images/ArrowCircleRightWhite.svg";
import { Navigation } from 'swiper/modules';
// import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Header from "../../../components/header/Header";
import { Modal } from "react-responsive-modal";
import Swal from 'sweetalert2';
import { BASE_URL, BASE_URL_IMAGE, END_POINT, METHODS } from '../../../helper/helper.js';
import { fetchData } from '../../../helper/ApiCall.js';
import dog1 from "../../../Images/dog.jpg";
import dog2 from "../../../Images/dog2.jpg";
import dog3 from "../../../Images/dog3.jpg";
import { original } from '@reduxjs/toolkit';
import ContactPage from './ContactPage.js';


const PrivacyPolicy = () => {


    const [blogPosts, setData] = useState([]);

    useEffect(() => {
        ContactHandler();
    }, []);

    const ContactHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetBlog);
            if (result.success == true) {
                setData(result.data);
                console.log(result);

            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <>

            <div className="top-banner"></div>
            <Header />

            <div className='main-container'>

                <div className="term-container">
                    <h1>Privacy Policy </h1>
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <p> Thank you for using our Dogit ("App"). This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information. By using the App, you agree to the terms outlined in this Privacy Policy. </p>
                        <ol type='i'>
                            <li>
                                <br></br>
                                <h3><b>1. Information We Collect:</b></h3>
                                <br></br>
                                <ol type="a">
                                    <li>
                                        <h3><b>a. Personal Information:</b></h3>
                                        <p>We may collect personal information such as your name, email address, and contact details when you register or interact with the App.</p>
                                    </li>
                                    <li>
                                        <h3><b>b. Training Data:</b></h3>
                                        <p>The App may collect information related to your dog's training progress, including behavior data, training activities, and performance metrics.</p>
                                    </li>
                                    <li>
                                        <h3><b>c. Device Information:</b></h3>
                                        <p>We may collect information about the device you use to access the App, including device type, operating system, and unique identifiers.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>2. How We Use Your Information:</b></h3>
                                <br></br>
                                <ol type="a">
                                    <li>
                                        <h3><b>a. Personalization:</b></h3>
                                        <p>We use your information to personalize your experience with the App, providing tailored training recommendations and content.</p>
                                    </li>
                                    <li>
                                        <h3><b>b. Communication:</b></h3>
                                        <p>We may use your contact information to send you important updates, newsletters, and promotional material related to the App.</p>
                                    </li>
                                    <li>
                                        <h3><b>c. Improvement of Services:</b></h3>
                                        <p>Collected data helps us analyze and improve the App's functionality, content, and features, ensuring a better user experience.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>3. Data Security:</b></h3>
                                <br></br>
                                <ol type="a">
                                    <li>
                                        <h3><b>a. Encryption:</b></h3>
                                        <p>We employ industry-standard encryption protocols to protect your personal information and training data during transmission and storage.</p>
                                    </li>
                                    <li>
                                        <h3><b>b. Access Controls:</b></h3>
                                        <p>Access to your personal information is restricted to authorized personnel and is strictly monitored to prevent unauthorized access.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>4. Sharing of Information:</b></h3>
                                <br></br>
                                <ol type="a">
                                    <li>
                                        <h3><b>a. Third-Party Service Providers:</b></h3>
                                        <p>We may share your information with third-party service providers who assist us in delivering and improving our services.</p>
                                    </li>
                                    <li>
                                        <h3><b>b. Legal Compliance:</b></h3>
                                        <p>We may disclose your information to comply with legal obligations, respond to requests from law enforcement, or protect our rights and interests.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>5. Your Choices:</b></h3>
                                <br></br>
                                <ol type="a">
                                    <li>
                                        <h3><b>a. Opt-Out:</b></h3>
                                        <p>You can opt-out of receiving promotional emails by following the instructions provided in the emails.</p>
                                    </li>
                                    <li>
                                        <h3><b>b. Access and Correction:</b></h3>
                                        <p>You have the right to access and correct your personal information. You can do this by contacting us through the contact information provided in the App.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>6. Changes to the Privacy Policy:</b></h3>
                                <br></br>
                                <p>We reserve the right to update and modify this Privacy Policy. Any changes will be effective upon posting the revised Privacy Policy on the App.</p>
                            </li>
                            <li>
                                <br></br>
                                <h3><b>7. Contact Us:</b></h3>
                                <br></br>
                                <p>If you have any questions or concerns about this Privacy Policy, please contact us at info@dogit.com.</p>
                            </li>
                        </ol>
                        <p>By using the Dogit App, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</p>
                    </div>
                </div >
            </div>

            <ContactPage />


            <div className='fotterDiv' >
                <div className='container ' >
                    <div className='row '>
                        <div className='col-lg-4'  >
                            <img src={playstore} className='playSImg' alt="footer" />
                            <img src={appstore} className='playSImg' alt="footer" />
                            <div className='footertitle' >USEFUL LINKS</div>
                            <ul className='list-unstyled' >
                                <li><a href='#' >Support</a></li>
                                <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                <li><a href='terms-conditions'>Terms & Conditions</a></li>
                                <li><a href='faq'>FAQ</a></li>
                                <li><a href='#features'>Features</a></li>
                                <li><a href='reviews'>Reviews</a></li>
                                <li><a href='feedback'>Feedback</a></li>
                                <li><a href='/blogs'>Blogs</a></li>

                            </ul>
                        </div>
                        <div className='col-lg-8' >

                        </div>
                        <img src={fotter} alt="footer" />
                    </div>

                </div>
            </div>

        </>

    );
};

export default PrivacyPolicy;
