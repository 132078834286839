import React, { useState } from 'react';
import { END_POINT, METHODS } from '../../helper/helper';
import { fetchData } from '../../helper/ApiCall';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { userdataUpdate } from '../../redux/reducers/userReducer';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const raw = JSON.stringify({
                email: username,
                password: password,
            });
            const result = await fetchData(END_POINT.UserLogin, METHODS.POST, raw);
            if (result.success === true) {
                console.log(result);
                // dispatch(userdataUpdate(result.data));
                if (result.success === true) {
                    navigate('/admin');
                }
            } else {
                Swal.fire({
                    title: 'Wrong',
                    text: result.message,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Cancel',
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading}>Admin Login</h2>
            <form style={styles.form}>
                <label style={styles.label}>
                    Username:
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={styles.input}
                    />
                </label>
                <label style={styles.label}>
                    Password:
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={styles.input}
                    />
                </label>
                <button type="button" style={styles.button} onClick={handleLogin}>
                    Login
                </button>
            </form>
        </div>
    );
};

const styles = {
    container: {
        width: '300px',
        margin: 'auto',
        marginTop: '50px',
        backgroundColor: '#cbd2d8',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        textAlign: 'center',
        color: '#333',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px',
    },
    label: {
        marginBottom: '10px',
        color: '#555',
        fontSize: '16px',
    },
    input: {
        padding: '8px',
        fontSize: '14px',
        marginBottom: '15px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    button: {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '4px',
        border: 'none',
        fontSize: '16px',
    },
};

export default Login;
