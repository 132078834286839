import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../dashscreen/DashCss.css";
import { END_POINT } from "../../helper/helper";
import { fetchData } from "../../helper/ApiCall";
import Swal from "sweetalert2";

const VideoScreen = () => {
    const [data, setWebsiteData] = useState([]);

    useEffect(() => {
        dataHandler();
    }, []);

    const dataHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetVideo);
            if (result.success === true) {
                setWebsiteData(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const deleteHandler = async (id) => {
        try {
            const result = await fetchData(END_POINT.DeleteVideo + id);
            if (result.success == true) {
                dataHandler(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <Link style={{ textDecoration: "none" }} to={"../add-video"}>
                <button className="btn btn-primary btn-no-arrow mt-4">
                    Add Video&nbsp;
                    <i className="fa-solid fa-plus"></i>
                </button>
            </Link>



            <div className="mt-4">
                <table className="table table-striped table-bordered dataTable no-footer">
                    <thead>
                        <tr>
                            <th>S.N</th>
                            <th>Title</th>
                            <th>Edit</th>
                            <th>Date</th>
                        </tr>
                    </thead>

                    <tbody>
                        {data.length > 0 ? (
                            data.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.title}</td>
                                    <td>
                                        <Link
                                            to={{
                                                pathname: "../add-video",
                                            }}
                                            state={{ id: item._id }}
                                        >
                                            <button>
                                                Edit
                                            </button>
                                        </Link>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                Swal.fire({
                                                    title: "Are you sure?",
                                                    text: "You want to delete!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Yes, delete it!",
                                                }).then((result) => {
                                                    if (result.isConfirmed) {
                                                        deleteHandler(item.id);
                                                        Swal.fire(
                                                            "Deleted!",
                                                            "The Data has been deleted.",
                                                            "success"
                                                        );
                                                    }
                                                });
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </td>

                                    <td>{item.updated_at.split("T")[0]}</td>
                                </tr>
                            ))
                        ) : (
                            <p>No Data available. Click the "Add Data" button to create one.</p>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VideoScreen;
