import { Routes, Route } from "react-router-dom";
import Home from "./components/homePage/Index";
import DashBoardScreen from "./dashscreen/DashBoardScreen";
import SideBarScreen from "./dashscreen/sidebar/SideBarScreen";
import React, { useRef, useEffect, useState } from "react";
import ContactScreen from "./dashscreen/dogit/ContactScreen";
import SubscriberScreen from "./dashscreen/dogit/SubscriberScreen";
import Login from "./dashscreen/dogit/AdminLoginScreen";
import VideoScreen from "./dashscreen/dogit/VideoScreen";
import AddVideoScreen from "./dashscreen/dogit/AddVideoScreen";
import BlogPage from "./components/homePage/LandingPage/BlogPage";
import BlogScreen from "./dashscreen/dogit/BlogScreen";
import AddBlogScreen from "./dashscreen/dogit/AddBlogScreen";
import BlogDetailPage from "./components/homePage/LandingPage/BlogDetailPage";
import HomeTagsScreen from "./dashscreen/dogit/HomeTagsScreen";
import AddHomeTags from "./dashscreen/dogit/AddHomeTags";
import PrivacyPolicy from "./components/homePage/LandingPage/PrivacyPolicy";
import TermsCondition from "./components/homePage/LandingPage/TermsCondition";
import Feedback from "./components/homePage/LandingPage/Feedback";
import Faq from "./components/homePage/LandingPage/Faq";
import Reviews from "./components/homePage/LandingPage/Reviews";

function App() {
  const [showNav, setShowNav] = useState(true);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsCondition />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/blog-detail" element={<BlogDetailPage />} />
        <Route path="/dogit-admin" element={<Login />} />
        <Route path="/admin/*" element={
          <SideBarScreen funcNav={setShowNav} />
        }>
          <Route index={true} element={<DashBoardScreen />} />
          <Route path="subscribers" element={<SubscriberScreen />} />
          <Route path="contacts" element={<ContactScreen />} />
          <Route path="video" element={<VideoScreen />} />
          <Route path="add-video" element={<AddVideoScreen />} />
          <Route path="blogs" element={<BlogScreen />} />
          <Route path="add-blogs" element={<AddBlogScreen />} />
          <Route path="home-meta-tags" element={<HomeTagsScreen />} />
          <Route path="add-home-meta-tags" element={<AddHomeTags />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
