import React from "react";
import "./OurStory.css"; // Import your CSS file for styling
// import { FEATURES } from "../../../Images/expert1.svg";
import boxOneImg from "../../../Images/expert1.svg";
import boxTwoImg from "../../../Images/expert2.svg";
import boxThreeImg from "../../../Images/expert3.svg";
import boxFourImg from "../../../Images/expert4.svg";



const SectionFive = () => {
    return (
        <>
            <div className="container" >
                <div className="row row_one mb-3  justify-center" >

                    <div className="col-lg-5 col-md-6 col-sm-12 mb-3 contentTwo " >
                        <div className="contentTwoDiv">
                            <div className="useCaseTitles">The founders</div>
                            <div className="useCaseDesc">Our founders, Jae and Leedor, are two friends sharing a passion for all things dogs, especially training. Having trained their own dogs to a high level, they wanted to help other owners reap the same benefits this bought, but in a modern and accessible way. And with that, the idea of Dogit was born! After lots of hard work, we are delighted to share an app developed by world-leading trainers which allows you to train any dog, regardless of age, breed, and size, at home for less than the price of a coffee per week. Training your dog to such high levels has never been easier or more accessible.
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-5 col-md-6 col-sm-12  mb-3" >
                        <img className="mx-auto" src={boxOneImg} alt={`img`} />
                    </div>
                </div>


                <div className="row row_two mb-3 justify-center" >
                    <div className="col-lg-5  col-md-6 col-sm-12 mb-3 imgorder" >
                        <img src={boxTwoImg} alt={`img`} />
                    </div>

                    <div className="col-lg-5 col-md-6 col-sm-12 mb-3 contentTwo " >
                        <div className="contentTwoDiv">
                            <div className="useCaseTitles">The trainer</div>
                            <div className="useCaseDesc"> We know what we’re talking about. We have a strong reputation; the depth of our experience is second to none. When we talk, people listen.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row row_three mb-3 w-90% justify-center" >

                    <div className="col-lg-5 col-md-6 col-sm-12 mb-3 contentTwo " >
                        <div className="contentTwoDiv" >
                            <div className="useCaseTitles">our philosophy</div>
                            <div className="useCaseDesc">We’re like no one you’ve met before - someone you want to know and be around. We’re a bit of fun, we’re interesting and we’re intriguing.
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-5 col-md-6 col-sm-12 mb-3 " >
                        <img className="mx-auto" src={boxThreeImg} alt={`img`} />
                    </div>
                </div>

                <div className="row row_four mb-3 w-90% justify-center" >
                    <div className="col-lg-5 col-md-6 col-sm-12 mb-3 " >
                        <img className="mx-auto" src={boxFourImg} alt={`img`} />
                    </div>

                    <div className="col-lg-5 col-md-6 col-sm-12 mb-3 contentTwo" >
                        <div className="contentTwoDiv">
                            <div className="useCaseTitles">OUR APPROACH</div>
                            <div className="useCaseDesc">"We’re happy, and warm – we draw people in. We value our relationships, and we get along with everyone. We’re easy to talk to, we leave people with a positive feeling.
                            </div>
                        </div>
                    </div>


                </div >

            </div>

        </>
    );
};

export default SectionFive;
