import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Header.css'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Header = ({ scrollToSection }) => {
    const [toggle, setToggle] = useState(false)
    console.log("toggle", toggle)


    return (
        <header>

            <div className="text-right ham pl-8 pr-8 py-4" onClick={() => setToggle(!toggle)} >
                {toggle ?
                    <CloseIcon fontSize="large" sx={{ color: "black" }} /> :

                    <MenuIcon fontSize="large" sx={{ color: "#000" }} />
                }

            </div>
            {toggle ?

                <nav>
                    <ui className={toggle ? "nav-options active" : "nav-options"} >
                        <li  >
                            <Link to="/#features" onClick={() => { scrollToSection('features'); setToggle(false) }} >App Features</Link></li>
                        <li >
                            <Link to="/#training" onClick={() => { scrollToSection('training'); setToggle(false) }}  >Training Videos</Link></li>
                        <li >
                            <Link to="/#appscreen" onClick={() => { scrollToSection('appscreen');; setToggle(false) }}>Sneak Peak of DOGIT</Link></li>
                        <li  >
                            <Link to="/#our-story" onClick={() => { scrollToSection('ourStory'); setToggle(false) }} >Our Story</Link></li>
                        <li >
                            <Link to="/#meet-experts" onClick={() => { scrollToSection('ourStory'); setToggle(false) }} >Experts</Link></li>
                        <li >
                            <Link to="/#newsletter" onClick={() => { scrollToSection('newsletter'); setToggle(false) }} >Newsletter</Link></li>
                        <li >
                            <Link to="/#contact-us" onClick={() => { scrollToSection('contactus'); setToggle(false) }} >Contact Us</Link></li>

                        <li >
                            <Link to="/">Home </Link></li>

                    </ui>
                </nav>
                : null
            }
        </header>
    );
};

export default Header;
