import React, { useRef, useEffect, useState } from 'react';
import './Landing.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import banner from "../../../Images/banner22.jpeg";
import logo from "../../../Images/logo2.svg";
// import mobilebanner from "../../../Images/mobilebanner.webp";
import twofon from "../../../Images/twofon.webp";
import dogvideo from "../../../Images/DogitComp2.mp4";
// import dogvideo1 from "../../../Images/dogvideo1.mp4";
// import dogvideo2 from "../../../Images/dogvideo2.mp4";
import fotter from "../../../Images/footer_logo2.png"
import playstore from "../../../Images/playstore.svg"
import appstore from "../../../Images/appstore.svg"
import d_one from "../../../Images/screen1.png";
import d_two from "../../../Images/screen2.png";
import d_three from "../../../Images/screen3.png";
import d_four from "../../../Images/screen4.png";
import thumb4 from "../../../Images/thumbbig.jpg"
// import d_four from "../../../Images/Schedule.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from "@uidotdev/usehooks";
import 'swiper/css';
import FourFon from './FourFon';
import VideosSwipe from "./VideosSwipe.js";
import OurStory from "./OurStory"
import ForVideos from "./ForVideos"
import prev from "../../../Images/ArrowCircleLeft.svg";
import next from "../../../Images/ArrowCircleRightWhite.svg";
import { Navigation } from 'swiper/modules';
// import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Header from "../../../components/header/Header";
import { Modal } from "react-responsive-modal";
import Swal from 'sweetalert2';
import { END_POINT, METHODS } from '../../../helper/helper.js';
import { fetchData } from '../../../helper/ApiCall.js';
import thumb3 from "../../../Images/img3.png"




const LandingPage = () => {
    const isSmallDevice = useMediaQuery("only screen and (max-width : 767px)");
    const [videoUrl, setVideoUrl] = useState(null)
    const videoRef = useRef(null);

    const [open, setOpen] = useState(false);

    const onOpenModal = () => {
        setOpen(true);

    };

    const onCloseModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            video.oncanplay = () => {
                video.play();
            };
        }
    }, []);

    const videoHandler = (url) => {
        console.log("url21", url)
        setVideoUrl(url)

    }

    const appscreenRef = useRef(null);
    const trainingRef = useRef(null);
    const featuresRef = useRef(null);
    const ourStoryRef = useRef(null);
    const newsletterRef = useRef(null);
    const contactUsRef = useRef(null);

    const scrollToSection = (sectionId) => {
        let targetRef;
        switch (sectionId) {
            case 'appscreen':
                targetRef = appscreenRef;
                break;
            case 'training':
                targetRef = trainingRef;
                break;
            case 'features':
                targetRef = featuresRef;
                break;
            case 'ourStory':
                targetRef = ourStoryRef;
                break;
            case 'newsletter':
                targetRef = newsletterRef;
                break;
            case 'contactus':
                targetRef = contactUsRef;
                break;
            default:
                break;
        }

        if (targetRef) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [formData, setformData] = useState({
        name: "",
        email: "",
        message: "",
        subject: "",
    });

    const handleChange = (e) => {
        setformData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const ContactSubmit = async (e) => {
        var raw = JSON.stringify({
            email: formData.email,
            name: formData.name,
            subject: formData.subject,
            message: formData.message,
        });
        console.log(raw);

        try {
            const finalEndpoint = END_POINT.PostContact;
            const result = await fetchData(finalEndpoint, METHODS.POST, raw);
            console.log("API response:", result);
            if (result.success == true) {
                // setFormSubmitted(result.data);
                Swal.fire({
                    title: "Confirmed",
                    text: result.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => { });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const SubscribeSubmit = async (e) => {
        var raw = JSON.stringify({
            email: formData.email,
        });
        console.log(raw);

        try {
            const finalEndpoint = END_POINT.PostSubscribe;
            const result = await fetchData(finalEndpoint, METHODS.POST, raw);
            console.log("API response:", result);
            if (result.success == true) {
                // setFormSubmitted(result.data);
                Swal.fire({
                    title: "Confirmed",
                    text: result.message,
                    icon: "success",
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then((result) => { });
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div>
                <button className='modalbtn' onClick={onOpenModal}>download dogit</button>
                {open && (
                    <Modal open={open} center  >
                        <div className='modal-box' >
                            <div class="contact-form-wrapper d-flex justify-content-center">
                                <div class="contact-modal">
                                    <div className='closeIcon' onClick={() => onCloseModal()} >
                                        <CloseIcon fontSize="large" sx={{ color: "#000" }} />
                                    </div>

                                    {/* <h2 class="title">GET IN TOUCH</h2> */}
                                    <h2 class="title">Download Dogit</h2>

                                    <p className='modalpara'>LET’S GET TO KNOW EACH OTHER</p>

                                    <div className='download-banner'>
                                        <div className='bannerstore'>
                                            <a href="https://play.google.com/store/apps/details?id=com.appetiser.dogit&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                                                <img src={playstore} className="w-full" alt="playstore" />
                                            </a>
                                        </div>
                                        <div className='bannerstore'>
                                            <a href="https://apps.apple.com/gb/app/dogit-dog-puppy-training/id6450585777" target="_blank" rel="noopener noreferrer">
                                                <img src={appstore} className="w-full" alt="appstore" />
                                            </a>
                                        </div>
                                    </div>


                                    {/* <div className='modalformDiv'  >

                                        <div className='row'>
                                            <div className="boxes col-6">
                                                <input type="text" class="form-control rounded border-white  form-input" id="name" name="name" placeholder="Name" required
                                                    value={formData.name}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>
                                            <div className="boxes col-6" >
                                                <input type="email" id="email" name="email" class="form-control rounded border-white form-input" placeholder="Email" required
                                                    value={formData.email}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>

                                        </div>
                                        <div className="boxes" >
                                            <input type="text" class="form-control rounded border-white form-input" id="subject" name="subject" placeholder="Subject" required
                                                value={formData.subject}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </div>
                                        <div className="boxes " >
                                            <textarea id="message" name="message" class="form-control rounded border-white form-text-area" rows="5" cols="30" placeholder="Message" required
                                                value={formData.message}
                                                onChange={(e) => handleChange(e)}
                                            ></textarea>
                                        </div>
                                        <div class="submit-button-wrapper">
                                            <input type="submit" value="Submit" onClick={() => {
                                                ContactSubmit();
                                            }} />
                                        </div>

                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
                }
            </div>

            <Header scrollToSection={scrollToSection} />

            <div className="banner_image">
                <div className='ban_img'>
                    <img src={banner} className="w-full" alt="banner" />
                </div>
                <div className='logo' style={{
                    bottom: '-66px',
                    left: '50%',
                    position: 'relative',
                    transform: 'translateX(-50%)',
                    width: '35%'
                }}>
                    <img src={logo} className="w-full" alt="logo" />
                </div>
            </div>


            <div className='mainContent' >
                <div className='storepara' >THE WORLD'S BEST TRAINERS IN YOUR POCKET</div>
                <div className='mainbannerstore'>
                    <div className='bannerstore'>
                        <a href="https://play.google.com/store/apps/details?id=com.appetiser.dogit&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                            <img src={playstore} className="w-full" alt="playstore" />
                        </a>
                    </div>
                    <div className='bannerstore'>
                        <a href="https://apps.apple.com/gb/app/dogit-dog-puppy-training/id6450585777" target="_blank" rel="noopener noreferrer">
                            <img src={appstore} className="w-full" alt="appstore" />
                        </a>
                    </div>
                </div>

            </div>


            <div className="two_phone"   >
                <img src={twofon} className="w-full" alt="twofon" />
            </div>

            <div className="app_feature  bg-[#958f62]" ref={featuresRef} id="features">
                <div className="container mx-auto py-6 px-6">
                    <div className="common_heading mb-3 text-4xl text-center font-bold ">APP FEATURES</div>
                    <div className="para_content text-center ">Our app contains a range of features designed to help make your dog training journey as fun and accessible as possible. They include on-demand training tutorials covering a range of abilities, progress trackers, sharing achievements with the Dogit community, and a shop for all your doggy needs.</div>

                    <div className="flex justify-center mt-10 gap-2">
                        <div className="dog_content ">
                            <div className="main_content text-right  my-4">
                                <div className="heading_name font-bold   text-2xl mb-2">Dog Training</div>
                                <div className="para ">Dogs can be trained to amazingly high levels, but are all individuals and learn at different paces. Our dog training takes a progressive and modular approach beginning with foundational and basic sessions leading up to more advanced behaviours.</div>
                            </div>
                            <div className="main_content text-right  my-4">
                                <div className="heading_name font-bold   text-2xl mb-2">Shop</div>
                                <div className="para ">As trainers and owners, we wanted to help the Dogit community by putting everything they needed for their dogs in a single convenient place. Our partner shop offers a full range of doggy accessories which are personally endorsed by Jae and Leedor.</div>
                            </div>
                        </div>
                        <div className="Phone_vid dog_content flex justify-center items-center  ">
                            {/* <div>
                                <video autoPlay loop preload="true">
                                    <source src={dogvideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div> */}
                            <div>
                                <video ref={videoRef} className='max-h-[45rem] rounded-[15px]' autoPlay loop preload="auto" muted>
                                    <source src={dogvideo} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                        <div className="dog_content text-left ">
                            <div className="main_content text-left my-4">
                                <div className="heading_name font-bold   text-2xl mb-2">Your Dog's Progress</div>
                                <div className="para ">Our app gamifies training, keeping it fun and letting you track progress. Dog training is a long-term effort, and we want users to be able to see just how far they and their four-legged friend have come.</div>
                            </div>
                            <div className="main_content text-left  my-4">
                                <div className="heading_name font-bold   text-2xl mb-2">Share Achievements</div>
                                <div className="para ">Our dogs’ achievements are absolutely something to share with your amazing community. Sharing achievements lets us celebrate them together, as you and your four-legged friend progress on your training journey.</div>
                            </div>
                        </div>
                    </div>
                    <div className='mobile_slider '>
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}

                            navigation={{
                                nextEl: '.nextCbtn',
                                prevEl: '.prevCbtn',

                            }}


                            modules={[Navigation]}

                        >
                            <SwiperSlide>
                                <div className="m_dog_content ">
                                    <div className="main_content text-right sm:text-center  my-4">
                                        <div className="heading_name font-bold   text-2xl mb-2">Dog Training</div>
                                        <div className="para ">Dogs can be trained to amazingly high levels, but are all individuals and learn at different paces. Our dog training takes a progressive and modular approach beginning with foundational and basic sessions leading up to more advanced behaviours.</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="m_dog_content ">
                                    <div className="main_content text-right sm:text-center  my-4">
                                        <div className="heading_name font-bold   text-2xl mb-2">Shop</div>
                                        <div className="para ">As trainers and owners, we wanted to help the Dogit community by putting everything they needed for their dogs in a single convenient place. Our partner shop offers a full range of doggy accessories which are personally endorsed by Jae and Leedor.</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="m_dog_content ">
                                    <div className="main_content text-left my-4">
                                        <div className="heading_name font-bold   text-2xl mb-2">Your Dog's Progress</div>
                                        <div className="para ">Our app gamifies training, keeping it fun and letting you track progress. Dog training is a long-term effort, and we want users to be able to see just how far they and their four-legged friend have come.</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="m_dog_content ">
                                    <div className="main_content text-left  my-4">
                                        <div className="heading_name font-bold   text-2xl mb-2">Share Achievements</div>
                                        <div className="para ">Our dogs’ achievements are absolutely something to share with your amazing community. Sharing achievements lets us celebrate them together, as you and your four-legged friend progress on your training journey.</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <div className='moveCbtn '>
                                <div className='prevCbtn mr-2' >
                                    <img src={prev} alt="prev" />
                                </div>

                                <div className='nextCbtn m2-2'>
                                    <img src={next} alt="next" />
                                </div>
                            </div>
                        </Swiper>
                    </div>
                </div>
            </div>

            {
                isSmallDevice ? <section className='our_device' ref={appscreenRef} id="appscreen"> <FourFon /> </section> :
                    <section className='our_device' ref={appscreenRef} id="appscreen"  >
                        <div className='container '>
                            <div className='m_devices row'>
                                <div className=' col-lg-3 col-sm-12 col-md-3 mb-3'>
                                    <div className='device_images  sm-p-4 md:p-4 lg:p-12 '>
                                        <img src={d_four} className="w-full" alt="banner" />
                                    </div>
                                </div>
                                <div className=' col-lg-3 col-sm-12 col-md-3 mb-3'>
                                    <div className='device_images  sm-p-4 md:p-4 lg:p-12'>
                                        <img src={d_three} className="w-full" alt="banner" />
                                    </div>
                                </div>
                                <div className=' col-lg-3 col-sm-12 col-md-3 mb-3'>
                                    <div className='device_images  sm-p-4 md:p-4 lg:p-12'>
                                        <img src={d_two} className="w-full" alt="banner" />
                                    </div>
                                </div>
                                <div className=' col-lg-3 col-sm-12 col-md-3 mb-3'>
                                    <div className='device_images  sm-p-4 md:p-4 lg:p-12'>
                                        <img src={d_one} className="w-full" alt="banner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
            }


            {
                isSmallDevice ?
                    <section className='our_device ' ref={trainingRef} id="training"> <ForVideos /> </section>
                    :
                    <section className='our_video' ref={trainingRef} id="training">
                        <div className='container flex justify-center'>
                            <div className='row justify-center'>
                                <div className='col-lg-12 col-sm-12 col-md-12'>
                                    <div className="common_heading   text-center font-bold ">DAILY VIDEOS</div>
                                </div>
                                <div className='col-lg-6 row col-sm-12 col-md-6 video_main'>

                                    <div className='col-lg-12 col-sm-12 col-md-12'>

                                        <div className='video_content'>
                                            <p>Our video bank shows app users how to implement the techniques we are teaching in an easy to follow and user-friendly manner. Videos do update daily, so there will always be something new to learn with your dog. </p>
                                        </div>
                                        <div className='related_videos '>
                                            <VideosSwipe />
                                        </div>

                                    </div>
                                </div>

                                <div className='col-lg-6 col-sm-12 col-md-6 right_video '>
                                    <div class="videoImgBigBox ">
                                        {videoUrl ?

                                            <iframe src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                            :
                                            <div style={{ height: "100%" }}>
                                                <img src={thumb3} alt="TDogit_thumb" className='img-fluid' />
                                                <div class="play-icon cursor-pointer " onClick={() => videoHandler('https://www.youtube.com/embed/xt5D8vAwlnM?si=xSCsK7IfapodCRIi')}  ><i class="bi bi-play-circle" ></i></div>

                                            </div>
                                        }
                                        <div class="VideoTitle"> Dog training - preparing for your puppy
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section >
            }




            <div className="homepage-container" ref={ourStoryRef} id="ourStory" >
                <OurStory />
            </div>


            <div className='container-fluid'>
                <div className='row'>
                    <div >
                        <div class="contact-form-wrapper d-flex justify-content-center" ref={newsletterRef} id="newsletter" >
                            <div class="content-wrap">

                                <h3 class="title">Our Newsletter</h3>

                                <p class="description">We’re constantly learning new things, and don’t like to keep them to ourselves! By subscribing to our newsletter you’ll be the first to hear about the exciting stuff that’s come our way. We don’t gatekeep tips and tricks, and our newsletter is the best and quickest way to stay in touch.
                                </p>

                                <div>
                                    <input type="email" name="email" id="email" class="form-control rounded border-white mb-3 form-input" placeholder="Email" required
                                        value={formData.email}
                                        onChange={(e) => handleChange(e)} />
                                </div>



                                <div class="submit-button-wrapper">
                                    <input type="submit" value="Submit" onClick={() => {
                                        SubscribeSubmit();
                                    }} />
                                </div>

                                <p class="description">We don’t share your personal information with anyone or company. Check out our Privacy Policy for more information.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='form-box' ref={contactUsRef} id="contactus" >
                        <div class="contact-form-wrapper d-flex justify-content-center">
                            <div class="contact-form">
                                <h3 className="title" style={{ color: 'black' }}>GET IN TOUCH</h3>


                                <div className='row'>
                                    <div className="boxes col-6">
                                        <input type="text" class="form-control rounded border-white  form-input" id="name"
                                            name="name" placeholder="Name"
                                            value={formData.name}
                                            onChange={(e) => handleChange(e)}
                                            required />
                                    </div>
                                    <div className="boxes col-6" >
                                        <input type="email" name="email" id="email" class="form-control rounded border-white form-input" placeholder="Email" required
                                            value={formData.email}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>

                                </div>
                                <div className="boxes" >
                                    <input type="text" class="form-control rounded border-white form-input" id="subject" placeholder="Subject" name="subject" required
                                        value={formData.subject}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                                <div className="boxes " >
                                    <textarea id="message" name="message" class="form-control rounded border-white form-text-area" rows="5" cols="30" placeholder="Message" required
                                        value={formData.message}
                                        onChange={(e) => handleChange(e)}
                                    ></textarea>
                                </div>
                                <div class="submit-button-wrapper">
                                    <input type="submit" value="Submit" onClick={() => {
                                        ContactSubmit();
                                    }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='fotterDiv' >
                <div className='container ' >
                    <div className='row '>
                        <div className='col-lg-4'  >


                            <a href="https://play.google.com/store/apps/details?id=com.appetiser.dogit&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                                <img src={playstore} className='playSImg' alt="footer" />
                            </a>

                            <a href="https://apps.apple.com/gb/app/dogit-dog-puppy-training/id6450585777" target="_blank" rel="noopener noreferrer">
                                <img src={appstore} className='playSImg' alt="footer" />
                            </a>



                            <div className='footertitle' >USEFUL LINKS</div>
                            <ul className='list-unstyled' >
                                <li><a href='#' >Support</a></li>
                                <li><a href='/privacy-policy'>Privacy Policy</a></li>
                                <li><a href='terms-conditions'>Terms & Conditions</a></li>
                                <li><a href='faq'>FAQ</a></li>
                                <li><a href='#features'>Features</a></li>
                                <li><a href='reviews'>Reviews</a></li>
                                <li><a href='feedback'>Feedback</a></li>
                                <li><a href='/blogs'>Blogs</a></li>

                            </ul>
                        </div>
                        <div className='col-lg-8' >

                        </div>
                        <img src={fotter} alt="footer" />
                    </div>

                </div>
            </div>




        </>

    )
}

export default LandingPage

