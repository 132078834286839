import React from 'react';
import './Landing.css';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import d_one from "../../../Images/screen1.png";
import d_two from "../../../Images/screen2.png";
import d_three from "../../../Images/screen3.png";
import d_four from "../../../Images/screen4.png";
import prev from "../../../Images/ArrowCircleLeft.svg";
import next from "../../../Images/ArrowCircleRight.svg";

// import required modules
import { EffectFade, Navigation, Pagination } from 'swiper/modules';



const FourFon = () => {



    return (
        <>
            <Swiper
                spaceBetween={30}
                loop={true}
                effect={'fade'}
                navigation={{
                    nextEl: '.netbtn',
                    prevEl: '.prevbtn',

                }}

                modules={[EffectFade, Navigation, Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className='mx-auto d-flex align-items-center justify-content-center col-lg-3 col-md-3 col-sm-12'>
                        <img src={d_four} className="w-full" alt="banner" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='device_images'>
                        <img src={d_three} className="w-full" alt="banner" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='device_images'>
                        <img src={d_two} className="w-full" alt="banner" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='device_images'>
                        <img src={d_one} className="w-full" alt="banner" />
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className='movebtn '>
                <div className='prevbtn' >
                    <img src={prev} alt="prev" />
                </div>
                <div className='netbtn'>
                    <img src={next} alt="next" />
                </div>
            </div>
        </>
    );
}

export default FourFon

