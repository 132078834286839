import React from "react";
import { Link, Outlet } from "react-router-dom";
// import SideMenu from "../../components/SideMenu";

const SideBarScreen = () => {
  return (
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex ">
        <a class="navbar-brand" href="#">
          <img
            src="/assets/img/dogit-logo.svg"
            style={{ width: '100px', height: '100px', margin: '0', padding: '0' }}
            alt="Dogit Logo"
          />

        </a>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <a href="" className="float-right">
          <i class="fa-solid fa-gear"></i>
        </a>
      </nav>
      <div className="row d-flex ">
        <div className="col-2 side-menu-container">
          <div className="accordion" id="accordionMenu">
            <div className="accordion-item side-menu-item-acc">
              <h2 className="accordion-header" id="headingOne">
                <Link style={{ textDecoration: "none" }} to={""}>
                  <button
                    className="accordion-button"
                    type="button"
                  //   data-bs-toggle="collapse"
                  //   data-bs-target="#collapseOne"
                  >
                    Dashboard
                  </button>
                </Link>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                data-bs-parent="#accordionMenu"
              >
                <div className="accordion-body">
                  <ul>
                    <li className="side-menu-item">Admin</li>
                  </ul>
                </div>
              </div>
            </div>


          </div>


          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"../admin/contacts"}>
              <span>Contacts</span>
            </Link>
          </div>

          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"../admin/subscribers"}>
              <span>Subscribers</span>
            </Link>
          </div>

          {/* <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"../admin/video"}>
              <span>Videos</span>
            </Link>
          </div> */}

          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"../admin/blogs"}>
              <span>Blogs</span>
            </Link>
          </div>

          <div className="side-menu-item">
            <Link style={{ textDecoration: "none" }} to={"../admin/home-meta-tags"}>
              <span>Home Meta Tags</span>
            </Link>
          </div>

          <div className="side-menu-item">
            <a href="">
              <span>Logout</span>
            </a>
          </div>


        </div>
        <div className="col-9 dash-content-section mt-4 mx-auto">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default SideBarScreen;
